import React from "react";
import Loader from "../Loading/Loader";

export default function SuspenseComponent() {
  return (
    <div style={{position: "fixed", top: "50%", left: "0", right: "0", bottom: "0", textAlign: "center"}}>
      <Loader />
    </div>
  );
}
