import React, { lazy, Suspense } from "react";
import { Provider } from "react-redux";
import { Route } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import configureStore, { history } from "./duck/store";
import { Button, Snackbar, Slide } from "@material-ui/core";
import * as serviceWorker from "./serviceWorker";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import SuspenseComponent from "./components/SuspenseComponent";
import APPCONFIG from "./constants/appConfig";
import "./main.css";

const App = lazy(() => import("./app"));

function TransitionRight(props) {
  return <Slide {...props} direction="right" />;
}

const theme = createMuiTheme(APPCONFIG.searchConfig.theme);

class Main extends React.Component {
  state = {
    openSnackbar: false,
    newVersionAvailable: false,
    waitingWorker: null,
  };

  componentDidMount = () => {
    if (process.env.NODE_ENV === "production") {
      serviceWorker.register({ onUpdate: this.onServiceWorkerUpdate });
    }
  };

  updateServiceWorker = () => {
    const { waitingWorker } = this.state;
    waitingWorker && waitingWorker.postMessage({ type: "SKIP_WAITING" });
    this.setState({ newVersionAvailable: false, openSnackbar: false });
    window.location.reload();
  };

  onServiceWorkerUpdate = (registration) => {
    this.setState({
      waitingWorker: registration && registration.waiting,
      newVersionAvailable: true,
      openSnackbar: true,
    });
  };

  handleClose = () => {
    this.setState({
      openSnackbar: false,
    });
  };

  render() {
    const { openSnackbar } = this.state;
    return (
      <Provider store={configureStore()}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={theme}>
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={openSnackbar}
              TransitionComponent={TransitionRight}
              onClose={this.handleClose}
              message="A new version is available!"
              action={
                <React.Fragment>
                  <Button
                    style={{ color: "rgb(220, 0, 78)", fontWeight: "900" }}
                    size="small"
                    onClick={this.updateServiceWorker}
                  >
                    Update
                  </Button>
                </React.Fragment>
              }
            />
            <Suspense fallback={<SuspenseComponent />}>
              <Route path="/" component={() => <App />} />
            </Suspense>
          </ThemeProvider>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default Main;
