const date = new Date();
const year = date.getFullYear();

const APPCONFIG = {
  brand: "INOVA",
  brandUrl: "www.relevancelab.com",
  year,
  searchConfig: {
    theme: {
      palette: {
        primary: {
          main: "#303f9f",
        },
        secondary: {
          main: "#303f9f",
        },
      },
      typography: {
        fontFamily:
          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important',
      },
    },
    tabConfig: [
      {
        sortLevel: 0,
        title: "Home",
        // searchConfigId: "19",
        bulkDownload: {
          isEnabled: false,
          apiUrl: "http://apigateway.relevancelab.com/prismmicro-search/solr/downloadExcel",
          buttonLabel: "Download",
          buttonTooltip: "Download",
          fileName: "`Excel.xlsx`",
          columnConfig: [
            {
              key: "insurancecompany",
              displayName: "Medical Program",
              sequence: 1,
              type: "string",
            },
            {
              key: "insurance_plan_name",
              displayName: "Insurance Plan Name",
              sequence: 2,
              type: "string",
            },
            {
              key: "patient_name",
              displayName: "Patient Name",
              sequence: 3,
              type: "string",
            },
            {
              key: "birth_date",
              displayName: "Birth Date",
              sequence: 4,
              type: "string",
            },
            {
              key: "gender",
              displayName: "Gender",
              sequence: 5,
              type: "string",
            },
            {
              key: "address",
              displayName: "Address",
              sequence: 6,
              type: "string",
            },
            {
              key: "city",
              displayName: "City",
              sequence: 7,
              type: "string",
            },
            {
              key: "zipcode",
              displayName: "Zipcode",
              sequence: 8,
              type: "string",
            },
            {
              key: "phone",
              displayName: "Phone",
              sequence: 9,
              type: "string",
            },
            {
              key: "patient_acc",
              displayName: "Patient Account Number",
              sequence: 10,
              type: "string",
            },
            {
              key: "total_charge",
              displayName: "Total Charge",
              sequence: 11,
              type: "string",
            },
            {
              key: "InsuranceId",
              displayName: "Insurance ID",
              sequence: 12,
              type: "string",
            }
          ]
        },
        searchList: {
          markOptions: {
            // separateWordSearch: false,
            accuracy: "partially",
            exclude: [".secondary-title-text-label", ".image-zoom-hint", ".related-docs"]
          },
          title: "Home",
          uniqueId: "id",
          html: "",
          css: "",
          autoSuggest: {
            isEnabled: true,
            apiUrl: "https://apigateway.relevancelab.com/prismmicro-search/solr/query-builder/search",
            appId: "Innova-Search",
            queryId: "19",
          },
          solrConfig: {
            apiUrl: "https://apigateway.relevancelab.com/prismmicro-search/solr/query-builder/search",
            appId: "Innova-Search",
            queryId: "19",
            query: "*",
          },
        },
        searchDetails: {
          html: "",
          css: "",
          enableFullScreen: true,
          searchId: "",
          searchIdKey: "id"
        },
      },
    ],
  },
};

export default APPCONFIG;
